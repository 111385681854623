<template>
  <b-card>
    <div class="col d-flex justify-content-center">
    <b-card>
      <div class=" justify-content-center"> 
        <label for="InputEmail1">{{ $t("OldPassword") }}</label>
        <vs-input class="w-full mb-base" type="password" v-model="password.old_password" />

        <label for="InputEmail1">{{ $t("NewPassword") }}</label>
        <vs-input class="w-full mb-base" type="password" @input="checkPassword()" v-model="password.new_password" />

        <!-- <span class="text-danger text-sm" v-show="errors.has('password')">{{errors.first("password")}}</span>  -->
        <ul class="ml-1 mt-2">
          <li v-bind:class="{ is_valid: contains_eight_characters }">
            8 {{ $t("Characters") }}
          </li>
          <li v-bind:class="{ is_valid: contains_number }">
            {{ $t("ContainsNumber") }}
          </li>
          <li v-bind:class="{ is_valid: contains_uppercase }">
            {{ $t("ContainsUppercase") }}
          </li>
          <li v-bind:class="{ is_valid: contains_special_character }">
            {{ $t("ContainsSpecialCharacter") }}
          </li>
        </ul>

        <div>
          <vs-button class="ml-auto mt-2" @click="ChangePassword"
            :disabled="!valid_password || !password.old_password">{{ $t("Save") }}</vs-button>
          <vs-button class="ml-4 mt-2" type="border" color="warning" @click="Reset">{{ $t("Reset") }}</vs-button>
        </div>

      </div>

    </b-card>

  </div>
  </b-card>

</template>
<script>
import {
  BAvatar, BButton, BCard, BCardHeader, BCardTitle, BCol, BForm,
  BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable
} from 'bootstrap-vue'

export default {
  components: {
    BAvatar, BButton, BCard, BCardHeader, BCardTitle, BCol, BForm,
    BFormCheckbox, BFormGroup, BFormInput, BFormRadioGroup, BMedia, BRow, BTable,
  },
  data() {

    return {
      contains_eight_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,

      password: {},
      valid_password: false
    };
  },
  methods: {
    checkPassword() {
      debugger;
      this.password_length = this.password.new_password.length;
      const format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;

      if (this.password_length > 8) {
        this.contains_eight_characters = true;
      } else {
        this.contains_eight_characters = false;
      }

      this.contains_number = /\d/.test(this.password.new_password);
      this.contains_uppercase = /[A-Z]/.test(this.password.new_password);
      this.contains_special_character = format.test(this.password.new_password);

      if (
        this.contains_eight_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true
      ) {
        this.valid_password = true;
      } else {
        this.valid_password = false;
      }
    },
    notifyAlert(color, title, text) {
      this.$vs.notify({
        color: color,
        title: title,
        text: text
      });
    },
    Reset() {
      this.password.old_password = "";
      this.password.new_password = "";
      this.contains_eight_characters = false;
      this.contains_number = false;
      this.contains_uppercase = false;
      this.contains_special_character = false;
    },
    ChangePassword() {
      this.$store
        .dispatch("auth/ChangePassword", this.password)
        .then(() => {
          this.$vs.notify({
            title: this.$t("success"),
            text: this.$t("PasswordChangedSuccessfully"),
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "success"
          });
          this.Reset();
        })
        .catch(res => {
          debugger;
          this.$vs.notify({
            title: this.$t("Error"),
            text: res.response.data.Message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        });
    }
  }
};
</script>
<style>
.is_valid {
  color: rgba(136, 152, 170, 0.8);
}

.is_valid:before {
  width: 50%;
}

li {
  /* margin-bottom: 8px; */
  color: #525f7f;
  position: relative;
}

li:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #2ecc71;
  position: absolute;
  left: 0;
  top: 50%;
  display: block;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
</style>
<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>
